<script lang="ts" setup>
import coingeckoImg from '~/assets/img/coingecko.svg'
import type { PriceData } from '~/store'
import { formatAmount, formatAndTrimAmount, formatMoney, formatPct } from '~/utils'

const props = defineProps({
  priceData: {
    type: Object as PropType<PriceData>,
    required: true,
  },
  name: {
    type: String,
    required: true,
  },
  symbol: {
    type: String,
    required: true,
  },
  coingeckoSymbol: {
    type: String,
    required: true,
  },
  loading: Boolean,
})

const isPriceData = computed(() => props.priceData)
const growIsNegative = computed(() => isPriceData.value ? isPriceData.value?.changePercent24 < 0 : true)
const price = computed(() => isPriceData.value ? formatMoney(isPriceData.value?.price) : 0)
const grow = computed(() => isPriceData.value ? formatPct.format(isPriceData.value?.changePercent24 / 100) : 0)
const last24 = computed(() => isPriceData.value ? formatAndTrimAmount(Math.abs(isPriceData.value?.change24), 2) : 0)
const value = computed(() => isPriceData.value ? formatAmount(isPriceData.value?.vol24, 2).replace('G', 'B') : 0)
</script>

<template>
  <div class="price-card">
    <div class="price-card__title">
      <div>{{ $t('other.price') }} ({{ name }}) ({{ symbol }})</div>
      <q-btn
        :href="`https://www.coingecko.com/en/coins/${coingeckoSymbol}`"
        class="price-card__link"
        color="white"
        round
        target="_blank"
        type="a"
        unelevated
      >
        <img :src="coingeckoImg" class="q-icon" alt="price card icon">
      </q-btn>
    </div>
    <price-card-skeleton v-if="loading" />
    <div v-else>
      <div class="price-card__main">
        <div class="price-card__main__price">
          ${{ price }}
        </div>
        <div
          class="price-card__main__grow"
          :class="{ 'price-card__main__grow--negative': growIsNegative }"
        >
          {{ grow }}
        </div>
      </div>
      <div class="price-card__additional">
        <div class="price-card__additional__price">
          {{ $t('other.last24h') }} {{ growIsNegative ? '-' : '+' }}${{ last24 }}
        </div>
        <div v-if="symbol === 'SOL'" class="price-card__additional__value">
          ${{ value }}
        </div>
      </div>
    </div>
  </div>
</template>
